img {
	max-width:100%;
	height:auto;
}
iframe {
	max-width:100%;
}
p {
	margin-bottom:1.5em;
	line-height:1.5;
}
a {
	&[href*=".pdf"] {
		i {
			// margin-left:.6em;
		}
		span {
			margin-left:.6em;
		}
	}
}
