.main--index {
  text-align:center;
  margin-bottom:0;
}
.main__content--index {
  @include breakpoint($sm) {
    text-align:center;
  }
  @include breakpoint($lg) {
    padding:0;
  }
}
.index__squares {
  margin-top:1em;
  @include breakpoint($sm) {
    display:flex;
    justify-content:stretch;
  }
}
.index__square {
  flex:1 1 auto;
  @include breakpoint($sm) {
    width:33.3%;
  }
  @include breakpoint($sm-only) {
    text-align:center;
  }
  .beta {
    margin:2em 0 1.5em;
    @include breakpoint($sm-only) {
      @include remcalc(16);
    }
    @include breakpoint($md) {
      margin-left:30px;
    }
  }
}
a.index__square {
  color:inherit;
}
.index__square__content {
  margin-bottom:3em;
  text-align:left;
  @include breakpoint($sm) {
    padding:0 15px;
  }
  @include breakpoint($md) {
    padding:0 30px;
  }
}
