.staff-member {
  margin:40px 0;
  @include breakpoint($lg) {
    margin:70px 0;
  }
}
.staff-member .beta {
  text-align:left;
  @include breakpoint($xs) {
    margin-top:2em;
  }
}
.staff-member__position {
  font-style:italic;
  @include remcalc(14);
}
.staff-member__image {
  @include breakpoint($lg) {
    max-width:312px;
  }
}
