.footer__wrapper {
  border-top:1px solid $brand-secondary;
  padding:2em 0;
  margin-top:20px;
}
.footer {
  display:flex;
  justify-content: space-between;
  flex-direction:column;
  align-items:stretch;
  @include breakpoint($sm) {
    align-items:center;
  }
  @include breakpoint($md) {
    flex-direction:row;
    align-items:stretch;
  }
}
.footer__right {
  @include breakpoint($md) {
    display:flex;
    flex-direction:column;
    justify-content:space-between;
  }
}
.menu__footer {
  text-align:left;
  width:100%;
  margin-top:2em;
  li {
    display:block;
    border:1px solid $brand-primary;
    &:not(:first-child) {
      border-width:0px 1px 1px;
    }
    a {
      padding:10px 30px;
      display:block;
      @include remcalc(10);
      color:$brand-primary;
    }
  }
}
.menu__copyright {
  @include remcalc(10);
  text-align:center;
  margin-top:2em;
  li {
    margin:.3em 0;
  }
  @include breakpoint($md) {
    text-align:right;
  }
}
.menu__footer, .menu__copyright {
  font-family:$headings-font-family;
  @include headings-letter-spacing();
  color:$brand-primary;
  @include breakpoint($sm) {
    li {
      display:inline-block;
      border:1px solid $brand-primary;
      border-width:0 0 0 1px !important;
      margin-left:15px;
      padding-left:15px;
      &:first-child {
        border-left:0;
        margin-left:0;
        padding-left:0;
      }
      a {
        padding:0;
      }
    }
  }
  @include breakpoint($md) {
    margin-top:0;
  }
}
.footer__rating {
  display:flex;
  align-items:stretch;
  font-family:$headings-font-family;
  @include headings-letter-spacing();
}
.footer__rating__left {
  background:$brand-secondary;
  color:white;
  font-weight:bold;
  @include remcalc(25);
  width:50px;
  height:50px;
  display:flex;
  align-items:center;
  justify-content:center;
  @include breakpoint($md) {
    @include remcalc(23);
  }
}
.footer__rating__right {
  @include remcalc(18);
  text-transform: uppercase;
  color:$brand-secondary;
  flex:1 0 auto;
  display:flex;
  justify-content:center;
  align-items:center;
  border:1px solid $brand-secondary;
  padding:0 20px;
  white-space:nowrap;
  @include breakpoint($md) {
    @include remcalc(16);
  }
}
