.alpha {
  font-family:$headings-font-family;
  @include headings-letter-spacing();
  @include remcalc(24);
  @include breakpoint($sm) {
    @include remcalc(42);
  }
}
h1 {
  @extend .alpha;
}
.beta {
  font-family:$headings-font-family;
  @include headings-letter-spacing();
  @include remcalc(16);
  color:$brand-primary;
  padding:.6em 0;
  margin:1em 0;
  border:1px solid $brand-secondary;
  border-width:1px 0;
  text-transform: uppercase;
  text-align:center;
  line-height:1.5;
  @include breakpoint($sm) {
    display:inline-block;
    @include remcalc(20);
    padding:.3em 0;
  }
}
h2 {
  @extend .beta;
}

.gamma {
  text-transform: uppercase;
  color:$brand-primary;
  @include remcalc(16);
  font-style:normal;
}

h3 {
  @extend .gamma;
}

.delta {
  text-transform: uppercase;
  color:$brand-primary;
  @include remcalc(12);
  font-style: italic;
}

h4 {
  @extend .delta;
}
