.sidebar {
  display:flex;
  flex-direction:column;
  @include breakpoint($sm) {
    flex-direction:row;
    justify-content:space-between;
    margin-top:2em;
  }
  @include breakpoint($lg) {
    flex-direction:column;
    margin-top:0;
  }
}
.sidebar__widget {
  background:$brand-secondary;
  color:white;
  display:flex;
  padding:1.5em;
  height:140px;
  a {
    color:white;
  }
  @include breakpoint($xs) {
    margin-top:1.5em;
  }
  @include breakpoint($sm) {
    width:calc(50% - 15px);
    height:165px;
  }
  @include breakpoint($md) {
    height:180px;
  }
  @include breakpoint($lg) {
    height:150px;
    margin-top:1.5em;
    width:100%;
    &:first-child {
      margin-top:0;
    }
  }
  &:hover {
    color:white;
  }
}
.sidebar__widget--1 {
  align-items:center;
  overflow:hidden;
  justify-content:space-between;
  img {
    height:140px;
    @include breakpoint($sm) {
      height:165px;
    }
    @include breakpoint($md) {
      height:180px;
    }
    @include breakpoint($lg) {
      height:150px;
    }
  }
}
.sidebar__widget--2 {
  text-align:center;
  flex-direction:column;
  justify-content: space-between;
  @include breakpoint($md) {
    justify-content:space-around;
  }
  .sidebar__widget__link {
    text-transform: uppercase;
  }
  .sidebar__widget__titles {
    margin-top: -9px;
    margin-bottom: 9px;
  }
}
.sidebar__widget--3 {
  flex-direction:column;
  justify-content:center;
  height:auto;
  li {
    span, i {
      @include remcalc(14);
    }
  }
}
.sidebar__widget__title {
  text-transform: uppercase;
  @include remcalc(20);
  text-align:center;
  font-family:$headings-font-family;
  @include headings-letter-spacing();
}
.sidebar__widget__subtitle {
  @include remcalc(16);
  text-transform: uppercase;
  margin-top:.8em;
  font-family:$headings-font-family;
  @include headings-letter-spacing();
  .tiny {
    @include remcalc(10);
    display:inline-block;
    margin:0 .8em;
    color:$brand-secondary--light;
  }
}
.sidebar__widget__link {
  background:$brand-secondary--dark;
  color:white;
  padding:.6em 0;
  margin:0 -1.5em -1.7em;
  font-family:$headings-font-family;
  @include headings-letter-spacing();
  &:hover {
    color:white;
  }
}
ul.sidebar__widget__list {
  padding-left:0;
  column-count:1;
  margin-top:1em;
  margin-bottom:0;
  font-family:$font-family-sans-serif;
  li {
    padding:.5em 0 .5em .8em;
    border-bottom:1px solid $brand-secondary--light;
    &:last-child {
      border:none;
    }
    &:before {
      display:none;
    }
  }
}
