.btn {
  font-family:$headings-font-family;
  text-transform: uppercase;
  padding-top:15px;
  padding-bottom:11px;
}

.btn-secondary {
  @include button-variant(white, $brand-secondary, $brand-secondary);
}
