$icon-font-path: '../fonts'; //override bootstrap
$brand-primary:#09573F;
$brand-secondary:#B6985A;
$brand-secondary--light:#d6ba7e;
$brand-secondary--dark:#967d4a;
$link-color:$brand-secondary;

$headings-font-family:'industry', sans-serif;
$font-family-sans-serif:'freight-sans-pro', sans-serif;
$font-size-base:16px;

$border-radius-base:0px;
