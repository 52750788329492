.slider__controls {
  background:$brand-primary;
  display:flex;
  justify-content: space-between;
  align-items:center;
  padding:1em 15px;
  margin-bottom:-30px;
  display:none;
  @include breakpoint($sm) {
    display:flex;
  }
}
.slider__dots {
  ul {
    list-style-type:none;
    padding-left:0;
    margin-bottom:0;
    li {
      display:inline-block;
      margin:0 4px;
      button {
        display:none;
      }
      &:after {
        content:'';
        width:15px;
        height:15px;
        border:1px solid white;
        border-radius:50%;
        display:block;
        cursor:pointer;
      }
      &.slick-active:after {
        background:white;
      }
    }
  }
}
.slider__control {
  color:white;
  cursor:pointer;
  font-size:27px;
  border:1px solid white;
  border-radius:50%;
  width:35px;
  height:35px;
  display:flex;
  justify-content: center;
  align-items: center;
}
.slider__wrapper {
  background:#222222;
}
.slider {
  @include breakpoint($xs) {
    position:relative;
    width:calc(100% + 30px);
    left:-15px;
  }
}
.slide {
  position:relative;
  &:not(.slick-slide) {
    &:not(:first-child) {
      display:none;
    }
  }
}
.slider__titles {
  text-transform: uppercase;
  position:absolute;
  z-index:2;
  bottom:2.2em;
  left:2.2em;
  @include breakpoint($xs) {
    width:80%;
    left:0;
    right:0;
    margin:0 auto;
  }
}
.slider__title {
  // @include remcalc(40);
  background:$brand-primary;
  line-height:1;
  padding:.3em 30px .15em;
  color:white;
  @include breakpoint($sm) {
    display:table;
  }
}
.slider__subtitle {
  background:white;
  color:$brand-primary;
  padding:.8em 30px;
  display:none;
  @include remcalc(14);
  font-family:$headings-font-family;
  @include headings-letter-spacing();
  @include breakpoint($sm) {
    display:inline-block;
  }
}
