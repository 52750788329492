.location {
  margin-top:2em;
  width:100%;
  @include breakpoint($sm) {
    width:33.3%;
    padding:0 15px;
    margin-top:20px !important;
  }
  @include breakpoint($md) {
    display:none;
    width:100%;
    padding:0 30px;
    line-height:1.8;
  }
  .gamma {
    margin-bottom:.4em;
    @include breakpoint($md) {
      padding-top:.6em;
      @include remcalc(20);
      margin-bottom:.1em;
      border-top:1px solid $brand-secondary;
    }
  }
  .location__practice {
    @include breakpoint($md) {
      padding-bottom:.6em;
      @include remcalc(14);
      border-bottom:1px solid $brand-secondary;
      margin-bottom:.8em;
    }
  }
}
hr.location {
  @include breakpoint($sm) {
    width:100%;
  }
}
.location__contact {
  text-transform: uppercase;
  margin:.4em 0;
  i {
    margin-right:1em;
    @include breakpoint($md) {
      display:none;
    }
  }
  @include breakpoint($md) {
    margin:0;
    text-align:center;
    a {
      @include button-variant(white, $brand-secondary, $brand-secondary);
      font-family:$headings-font-family;
      @include headings-letter-spacing();
      text-transform: uppercase;
      display:block;
      margin:2.5em -30px 0 !important;
      padding:15px 30px 11px 30px;
    }
  }
}
.locations__filters {
  margin-bottom:20px;
  .delta {
    margin-top:1.2em;
  }
  @include breakpoint($sm) {
    margin-bottom:40px;
  }
  @include breakpoint($md) {
    button {
      border-top:0;
      &:first-child {
        border-left:0;
      }
      &:nth-child(2) {
        border-right:0;
      }
    }
  }
}
.locations__placeholder {
  text-align:center;
  margin-bottom:2.5em;
}
.location__address, .location__address2, .location__service-area {
  @include breakpoint($md) {
    @include remcalc(16);
  }
}
#main__content--locations {
  @include breakpoint($md) {
    background:white;
    position:absolute;
    z-index:2;
    width:310px;
    top:300px;
    border:1px solid $brand-secondary;
  }
  @include breakpoint($lg) {
    top:380px;
    width:350px;
    padding:0;
  }
}
.locations__filters__buttons {
  @include breakpoint($sm) {
    display:flex;
  }
}
.locations__filters__button {
  @include breakpoint($xs) {
    &:not(:first-child) {
      border-top:none;
    }
  }
  @include breakpoint($sm) {
    &:not(:first-child) {
      border-left:none;
    }
  }
}

.service-map {
  width:100%;
  padding-top:85.83%;
  height:0;
  position:relative;
  svg {
    position:absolute;
    top:0;
    left:0;
  }
  #United_States, #Canada {
    g.active path {
      fill:$brand-secondary;
    }
    circle {
      pointer-events:none;
    }
  }
}
