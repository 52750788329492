.main {
  margin:23px 0;
  @include breakpoint($sm) {
    margin:40px 0;
  }
  @include breakpoint($lg) {
    margin:80px 0 40px;
    @include remcalc(18);
  }
  ul {
  	list-style-type:none;
  	padding-left:0;
    margin-bottom:1.5em;
  	@include breakpoint($sm) {
  		column-count:2;
  	}
  }
  $list-decoration-padding:22px;
  li {
    padding-left:calc(2 * #{$list-decoration-padding};
    overflow:hidden;
    &:before {
      content: '\2022 ';
      color: $brand-secondary;
      padding-right: $list-decoration-padding;
      margin: 0 0 0 -#{$list-decoration-padding};
      width: $list-decoration-padding;
      float:left;
    }
    span, i {
      @include remcalc(14);
    }
  }
}

.beta--main__lead {
  padding-left:2em;
  padding-right:2em;
  margin-bottom:1.5em;
  @include breakpoint($sm) {
    padding-left:0;
    padding-right:0;
  }
}
.main__content {
  text-align:left;
  @include breakpoint($lg) {
    padding:0 40px;
  }
  *:first-child {
    margin-top:0;
  }
}
