.header {
  padding:25px 0 15px;
  position:relative;
  &:after {
    position:absolute;
    width:100%;
    height:1px;
    background:$brand-secondary;
    content:'';
    bottom:-8px;
    z-index:3;
  }
}
.header__flex {
  position:relative;
  @include breakpoint($md) {
    align-items:flex-end;
    display:flex;
    justify-content: space-between;
    align-items:center;
  }
  @include breakpoint($lg) {
    align-items:stretch;
  }
  > * {
    flex:1;
  }
}
.header__logo {
  max-width:150px;
  display:block;
  @include breakpoint($sm) {
    max-width:200px;
  }
}
.header__menus {
  // display:flex;
  // flex-direction:column;
  // justify-content:space-between;
}
.menu {
  padding-left:0;
  list-style-type:none;
  text-align:right;
  margin-bottom:0;
  text-transform: uppercase;
  font-family:$headings-font-family;
  @include headings-letter-spacing();
  li {
    display:inline-block;
    margin:0 -.15em;
  }
  a {
    color:$brand-primary;
  }
  &.menu--secondary {
    li {
      border:1px solid #cccccc;
      + li {
        border-left:none;
      }
      a {
        padding:.5em 1.5em .4em;
      }
    }
  }
  &.menu--primary {
    @include breakpoint($md) {
      display:flex;
      justify-content:flex-end;
      margin-top:1.8em;
    }
    li {
      flex:0 1 auto;
      margin:0 15px;
      text-align:center;
      a {
        padding:0;
      }
      &:last-child {
        margin-right:0;
      }
    }
  }
  &.menu--primary, &.menu--secondary {
    a {
      display:block;
      padding:.4em 2em;
      @include remcalc(16);
      @include breakpoint($md) {
        @include remcalc(14);
      }
    }
  }
  &.menu--mobile {
    position:absolute;
    padding:15px 15px;
    z-index:4;
    background:white;
    width:100vw;
    @include remcalc(18);
    display:none;
    li {
      display:block;
      margin:8px 0;
    }
  }
}
.menu--mobile__trigger {
	width:100%;
	height:5px;
	background:$brand-primary;
	display:block;
	margin:5px 0;
	transition:all .3s;
	cursor:pointer;
	&.top {
		margin-top:0;
	}
	&.top, &.bottom {
		transform-origin:20% center;
	}
	&.middle {
		transform-origin:center center;
	}
}
.menu--mobile__trigger__wrapper {
  width:42px;
  position:relative;
	display:block;
  position:absolute;
  right:0;
  top:50%;
  transform:translateY(-50%);
  &.active {
    .menu--mobile__trigger {
    	width:120%;
      &.bottom {
		    transform: rotate(-45deg);
      }
    	&.top {
    		transform: rotate(45deg);
    	}
    	&.middle {
    		opacity:0;
    	}
    }
  }
}
