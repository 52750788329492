/*Default mixins*/
$xs: ( max:  767px );
$sm: ( min:  768px );
$md: ( min:  992px );
$lg: ( min: 1200px );
$sm-only: ( min: map-get($sm, min), max: map-get($md, min) - 1 );
$md-only: ( min: map-get($md, min), max: map-get($lg, min) - 1 );

@mixin breakpoint($map) {
  $query: "";
  @if map-has-key($map, min) { $query: append($query, "(min-width: #{map-get($map, min)})") }
  @if map-has-key($map, min) and map-has-key($map, max) { $query: append($query, "and") }
  @if map-has-key($map, max) { $query: append($query, "(max-width: #{map-get($map, max)})") }
  @media screen and #{$query} { @content; }
}

@mixin remcalc($px) {
	font-size:$px+px;
	font-size:($px/10)+rem;
}

@mixin headings-letter-spacing() {
  letter-spacing:.1em;
}
