.row.row--no-padding {
  [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.row.row--inline {
	[class*="col-"] {
		display:inline-block;
		float:none;
		margin:0 -0.125em;
	}
}

.row.row--flex {
  display:flex;
}

.address {
  @include breakpoint($lg) {
    padding-left:20px;
  }
}
