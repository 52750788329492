select {
  color:$brand-secondary;
  text-transform: uppercase;
  border:1px solid $brand-secondary;
  @include remcalc(16);
  display:block;
  width:100%;
  font-family:$headings-font-family;
}
button {
  color:$brand-secondary;
  text-transform: uppercase;
  @include remcalc(16);
  background:none;
  font-family:$headings-font-family;
  border:1px solid $brand-secondary;
  outline:none;
  text-align:center;
  display:block;
  width:100%;
  &.active {
    background:$brand-secondary;
    color:white;
  }
}
select, button {
  padding:.7em 0 .5em;
}
